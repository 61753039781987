import {
  Weekday,
  TransactionDocument,
  TranslateKeys,
  OgAccountType,
} from '@og-shared/types';

export const OG_APP_STORE_URL =
  'https://apps.apple.com/us/app/one-goal-finance/id1572722093' as const;
export const OG_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.onegoal.onegoal' as const;

export const IGNORE_BUDGET_ID = 'IGNORE' as const;

export const UNCATEGORIZED_BUDGET_ID = 'UNCATEGORIZED' as const;

export const fillDays: TranslateKeys[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export function getWeekDayString(fillDay: Weekday): TranslateKeys {
  return fillDays[fillDay] ?? 'SUNDAY';
}

export const ogDemoOne = 'one-goal-demo-1' as const;

export function isDemoGroupId(id: string | null) {
  if (!id) return false;
  const demoGroups: string[] = [ogDemoOne];
  return demoGroups.includes(id);
}

export const INCOME_BUDGET_ID = 'INCOME' as const;
export const TRANSFER_SUBTYPE = 'TRANSFER' as const;

export enum BudgetType {
  SPENDING = 'SPENDING',
  SAVINGS = 'SAVINGS',
  BILL = 'BILL',
  INCOME = 'INCOME',
} // | 'OTHER';

export const BANK_TRANSACTION_TYPES: TransactionDocument['type'][] = [
  'plaid',
  'manual_entry',
  'asa',
];

export const ACCOUNT_ASSET_TYPES: OgAccountType[] = [
  'brokerage',
  'depository',
  'investment',
  'property',
];
export const ACCOUNT_LIABILITY_TYPES: OgAccountType[] = ['credit', 'loan'];
